<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Employee Groups</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Employee Groups</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>

            <b-row>
                <b-col lg="6" class="mb-1rem">
                    <router-link
                        v-if="hasPermission('CAN_MANAGE_EMP_GROUPS')"
                        :to="{name: 'create-employee-group'}"
                        class="btn btn-primary text-right"
                        tag="button"
                    >
                        <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Create Employee Group
                    </router-link>
                </b-col>
                <!-- <b-col lg="6" class="mb-1rem">
                    <b-form-group
                        label="Search"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                        style="font-weight:bold; font-size:1rem !important;"
                    >
                        <b-input-group size="md">
                            <b-form-input
                                v-model="filter"
                                type="search"
                                id="filterInput"
                                placeholder="Search Name"
                                v-on:keyup.enter="onFiltered"
                                @search="clearSearch"
                            ></b-form-input>
                            <b-input-group-append>
                                <b-button
                                    :disabled="!filter"
                                    @click="onFiltered"
                                    class="btn btn-primary"
                                >Search</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col> -->
            </b-row>

            <b-table
                show-empty
                striped
                hover
                :items="items"
                :fields="fields"
                no-local-sorting
                @sort-changed="customSorting"
                @filtered="onFiltered"
                responsive
            >
                <template v-slot:cell(actions)="row">
                    <span class="actions">
                        <router-link class="circle" v-b-tooltip.hover title="Edit" v-if="hasPermission('CAN_MANAGE_EMP_GROUPS')" :to="{name: 'edit-employee-group', params:{id:row.item.id}}">
                            <font-awesome-icon
                                icon="pen"
                            ></font-awesome-icon>
                        </router-link>
                    </span>
                </template>
            </b-table>

        </b-container>
    </div>
</template>
<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            sortBy: "name",
            sortDesc: false,
            fields: [
                { key: "name", sortable: true },
                { key: "actions", label: "Actions" }
            ],
            items: [],
            api_error: "",
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            urlBody: {
                query: "",
                sort_by: "",
                order: "",
            },
            filter: null,
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_EMP_GROUPS");
        this.getEmployeeGroups();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        getEmployeeGroups: function() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {};
            var urlBody =Object.keys(this.$route.query).length > 0 ? this.$route.query : this.deleteEmptyKeys(this.urlBody);
            if (this.$route.query && this.$route.query.query) {
                this.urlBody.query = this.$route.query.query;
            }
            query["params"] = urlBody;
            this.axios.get(this.$api.get_employee_groups, query)
                .then(response => {
                    this.items = response.data.data;
                    this.updateProgressBar(true);
                }).catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        customSorting: function(ctx) {
            if (ctx.sortBy == "" || ctx.sortBy == null) {
                return;
            }
            this.urlBody.order = ctx.sortDesc ? "asc" : "desc";
            this.urlBody.sort_by = ctx.sortBy;
            let urlBody = this.deleteEmptyKeys(this.urlBody);
            this.api_error = "";
            this.$router
                .push({
                    path: this.$route.params[0],
                    query: urlBody
                }).catch(()=>{});
            this.getEmployeeGroups();
        },

        onFiltered() {
            this.urlBody.query = this.filter;
            let urlBody = this.deleteEmptyKeys(this.urlBody);
            this.api_error = "";
            this.$router
                .push({
                    path: this.$route.params[0],
                    query: urlBody
                }).catch(()=>{})
            this.getEmployeeGroups();
        },

        clearSearch (event) {
            if (event.target.value === ""){
                if(this.urlBody.query != ''){
                    this.urlBody.query = '';
                    var newQuery = this.deleteEmptyKeys(this.urlBody);
                    this.$router
                        .push({
                            path: this.$route.params[0],
                            query: newQuery
                        })
                    this.getEmployeeGroups()
                }
            }
        }
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>